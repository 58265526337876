import * as React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import HeroList from "./HeroList";
import TextInsertion from "./TextInsertion";
import { makeStyles } from "@fluentui/react-components";
import { Ribbon24Regular, LockOpen24Regular, DesignIdeas24Regular } from "@fluentui/react-icons";
// import {insertText} from "../taskpane";

const useStyles = makeStyles({
  root: {
    minHeight: "80vh",
  },
});

const App = (props) => {
  const { title } = props;
  const styles = useStyles();
  // The list items are static and won't change at runtime,
  // so this should be an ordinary const, not a part of state.
  const listItems = [
    {
      icon: <Ribbon24Regular />,
      primaryText: "Achieve more with Office integration",
    },
    {
      icon: <LockOpen24Regular />,
      primaryText: "Unlock features and functionality",
    },
  ];

  return (
    <div className={styles.root}>
      {/* <Header logo="assets/logo-filled.png" title={title} message="Welcome" /> */}
      <Header logo="assets/logo-filled.png" title={title} />
      <HeroList message="Explore with eeFunction" items={listItems} />
      {/* <TextInsertion insertText={"run"} /> */}
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
